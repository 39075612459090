import {
  ActionIcon,
  Divider,
  Text,
  useMantineColorScheme,
} from "@mantine/core";
import { useLinks } from "./popover-link/useLinks";
import { useColors } from "../../../hooks/colors/useColors";
import Link from "next/link";
import { IconMenu2, IconVideo } from "@tabler/icons";
import { useWindowSize } from "../../../hooks/misc/useWindowSize";
import { InfoMenu } from "./popover-link/InfoMenu";
import { mobile } from "../../../constants/env";
import { CategoriesMenu } from "./popover-link/CategoriesMenu";
import { useUser } from "../../../hooks/react-query/useUser";
import Image from "next/image";
import { ButtonLink } from "../../UI/button-link/ButtonLink";
import { MobileDrawer } from "./MobileDrawer";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { DarkModeSwitch } from "../../UI/dark-mode-switch/DarkModeSwitch";
import { AnonymousButton } from "./AnonymousButton";
import { useSpecialistList } from "../../../hooks/react-query/useSpecialistList";
import { useSpecialtyList } from "../../../hooks/react-query/useSpecialtyList";
import dynamic from "next/dynamic";
import { getCookie } from "cookies-next";
import { isMobile } from "react-device-detect";
import { NavbarLanguagePicker } from "../../UI/language-picker/navbar-language-picker";

type MatchesDarkMode<T extends {}> = T & {
  matches: boolean;
};

const UserMenu = dynamic(
  () => import("./UserMenu").then((mod) => mod.UserMenu),
  {
    ssr: false,
  }
);

export function Navbar() {
  const { colors, setDarkMode, getDarkModeCookieValue, isDarkMode } =
    useColors();
  const { specialtyLinks, specialistLinks } = useLinks();
  const { specialists } = useSpecialistList();
  const { specialties } = useSpecialtyList();
  const { windowSize } = useWindowSize();
  const { user } = useUser();
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const { i18n } = useTranslation("common");
  const { setColorScheme } = useMantineColorScheme();

  const modeMe = (e: MatchesDarkMode<any>) => {
    setDarkMode(!!e.matches);
  };

  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (typeof getCookie("colorScheme") === "undefined") {
      toggleDarkMode(matchMedia.matches);
    } else {
      setDarkMode(getDarkModeCookieValue());
    }
    matchMedia.addEventListener("change", modeMe);
    return () => matchMedia.removeEventListener("change", modeMe);
  }, []);

  function toggleDarkMode(value: boolean) {
    const newValue = value ? "dark" : "light";
    setColorScheme(newValue);
    setDarkMode(value);
  }

  const navbarContainerClasses = "mx-auto py-2 flex justify-between px-2";

  const Logo = () => (
    <Link href="/" className={isMobile ? "mt-3" : undefined}>
      <Image
        alt="Psiworks"
        className="hover"
        width={isMobile ? 260 : 300}
        height={isMobile ? 40 : 53}
        src="/img/psi-works-log.svg"
      />
    </Link>
  );

  return (
    <Fragment>
      {windowSize.width && (
        <div
          className="fixed top-0 left-0 right-0 z-[999]"
          style={{ backgroundColor: colors.white, opacity: 1 }}
        >
          {windowSize.width && (
            <div
              className={
                windowSize.width < 1368
                  ? navbarContainerClasses
                  : "container " + navbarContainerClasses
              }
            >
              <div className="flex items-center gap-12">
                <Logo />
                {!mobile && (
                  <Fragment>
                    {/*{user && (*/}
                    <ButtonLink
                      href="/agendar"
                      color={colors.primary}
                      title={i18n.t("apps")}
                      textColor="white"
                    />
                  </Fragment>
                )}
              </div>
              {!mobile && (
                <div className="flex items-center gap-4">
                  {specialties && (
                    <CategoriesMenu
                      categoryMap={specialtyLinks()}
                      href="/especialidades"
                    />
                  )}
                  {specialists && (
                    <CategoriesMenu
                      categoryMap={specialistLinks()}
                      translate
                      doctors
                      href="/especialistas"
                    />
                  )}
                  <Link href="/blog">
                    <Text c={colors.dark} className="hover !font-bold">
                      {i18n.t("blog.name")}
                    </Text>
                  </Link>
                  <InfoMenu />
                  {!mobile && (
                    <Fragment>
                      {user && (
                        <ButtonLink
                          href="/"
                          icon={<IconVideo color={colors.white} />}
                          color={colors.secondary}
                          title={i18n.t("video-calls")}
                        />
                      )}
                      {!user && (
                        <AnonymousButton
                          title={i18n.t("video-calls")}
                          color={colors.secondary}
                          forbiddenIcon={
                            <IconVideo
                              color={colors.secondary}
                              size={200}
                              stroke={1}
                            />
                          }
                          warning={`${i18n.t(
                            "videoMeeting.anonymous"
                          )} ${i18n.t("booking.anonymous")}`}
                          buttonIcon={<IconVideo color="white" />}
                        />
                      )}
                      <NavbarLanguagePicker />
                    </Fragment>
                  )}
                  <UserMenu />
                  <DarkModeSwitch />
                </div>
              )}
              {mobile && (
                <ActionIcon
                  aria-label="menu"
                  title="menu"
                  color={colors.white}
                  className="fixed top-5 right-2"
                  onClick={() => setOpenMobileMenu(true)}
                >
                  <IconMenu2 color={colors.primary} />
                </ActionIcon>
              )}
            </div>
          )}
          <Divider />
          <MobileDrawer
            opened={openMobileMenu}
            setOpened={setOpenMobileMenu}
            logo={<Logo />}
          />
        </div>
      )}
    </Fragment>
  );
}
